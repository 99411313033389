import React from "react"
import {Link} from "gatsby"
import "./ctaList.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactReadMoreReadLess from "react-read-more-read-less"
import CustomMarkdown from "../../utils/customMarkdown"



const CTAList = ({title, subtitle, contents, cardStyle, topPadding, bottomPadding}) => {

    var customClass = ""
    if(cardStyle){
        customClass = "cardStyle"
    }else{
        customClass = "variantStyle"
    }

    return(
        <>
        <div className={`uk-card uk-card-muted additional-options ${customClass} ${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''} lateral-padding ctalist-container`}>
        
            <div className="additional-options-container uk-align-center ">
                <div uk-scrollspy="cls: uk-animation-slide-bottom; target: .ctaList-title; delay: 500; repeat: true">
                    <h2 className="uk-text-center ctaList-title">{title}</h2>
                    {subtitle?<div className="uk-text-center additional-options-subtitle">{subtitle}</div>:""}
                </div>
                
                <div className="uk-child-width-1-2@m uk-child-width-1-1@s uk-child-width-expand@l uk-text-center uk-grid-match"  data-uk-grid>
                
                    {contents.map((item, index) => {

                        if(cardStyle){
                            return(
                            
                                <div className="cta-card">
                                    <a key={index} href={item.URL} target="_blank" rel="noopener noreferrer" className="card-link uk-card uk-card-default uk-card-body">
                                        <div className="uk-card-media-top">
                                            
                                            <GatsbyImage className="cta-card-image" image={item.Image.gatsbyImageData} alt={item.Alt}></GatsbyImage>
                                        </div>
                                        <div className="cta-card-title">{item.Title}</div>
                                        <CustomMarkdown children={item.Description}></CustomMarkdown>
                                        {item.URL?(cardStyle?"":<Link to={item.URL} target="_blank" rel="noopener noreferrer" className="cta-card-read-more" aria-label={"Read more details about " + item.Title}>read more</Link>):""}
                                    </a>
                                </div>
                            )
                        }else{
                            return(
                            
                                <div className="cta-card">
                                    <div key={index} className="card-link uk-card uk-card-default uk-card-body">
                                        <div className="uk-card-media-top">
                                            
                                            <GatsbyImage className="cta-card-image" image={item.Image.gatsbyImageData} alt={item.Alt}></GatsbyImage>
                                        </div>
                                        <div className="cta-card-title">{item.Title}</div>


                                        
                                        { item.Description ? 
                                            <p aria-label={item.Description}>
                                                <ReactReadMoreReadLess
                                                    charLimit={item.DescriptionCharLimit||500}
                                                    readMoreText={"read more"}
                                                    readLessText={"read less"}
                                                    readMoreClassName="read-more-tag"
                                                    readLessClassName="read-more-tag"
                                                    >
                                                    

                                                    {item.Description}

                                                    
                                                </ReactReadMoreReadLess>
                                            </p> : ''
                                        }
                                        <br/>
                                        {item.URL?(cardStyle?"":<Link to={item.URL} target="_blank" rel="noopener noreferrer" className="read-more-btn" aria-label={"Read more details about " + item.Title}>{item.URLLabel||"More details"}</Link>):""}

                                    </div>
                                </div>
                            )
                        }
                        
                    })
                    }                   
                </div>
            </div>
        </div>
        </>
    )
}

export default CTAList